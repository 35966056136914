import TermDocument from "../@types/services/TermDocument";
import api from "./api";

type TermDocumentForm = {
  term_id: string;
  justification?: string;
  attachment_pt_br: File;
  attachment_en: File;
};

export const fetchTermDocument = async (id: string): Promise<TermDocument> => {
  const response = await api.get<TermDocument>(`/term_documents/${id}`, {
    baseURL: "/vsupplierregistration/api/v2",
  });
  return response.data;
};

export const createTermDocument = async (
  data: TermDocumentForm,
  attach_pt_br: File,
  attach_en: File,
): Promise<TermDocument> => {
  const response = await api.post<TermDocument>(`/term_documents`, data, {
    baseURL: "/vsupplierregistration/api/v2",
  });
  await addAttachment(response.data.id, attach_pt_br, attach_en);

  return response.data;
};

export const addAttachment = async (
  termDocumentId: string,
  attachment_pt_br: File,
  attachment_en: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append("attachment_pt_br", attachment_pt_br);
  formData.append("attachment_en", attachment_en);

  await api.post<TermDocument>(
    `/term_documents/${termDocumentId}/add_attachment`,
    formData,
    {
      baseURL: "/vsupplierregistration/api/v2",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};
