import { mdiDelete, mdiDownload, mdiPlus, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Attachment from "../../../@types/services/Attachment";
import useAuth from "../../../hooks/useAuth";
import {
  addAttachments,
  deleteAttachments,
  fetchAttachments,
} from "../../../services/mm_requests";

type formValues = {
  file_attachments: File[];
};

type DuimpRequestAttachmentsProps = {
  mmRequestId: number;
  [key: string]: any;
};

const DuimpRequestAttachments = ({
  mmRequestId,
  ...props
}: DuimpRequestAttachmentsProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<formValues>({
    defaultValues: {
      file_attachments: [],
    },
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [isFetchingAttachments, setIsFetchingAttachments] = useState(true);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const onSubmit: SubmitHandler<formValues> = async (data) => {
    setLoadingSubmit(true);
    console.log(data);
    await addAttachments(data.file_attachments, mmRequestId.toString())
      .then((response) => {
        setFileName("");
        fetchData();
        enqueueSnackbar(
          i18next.t("mmRequests.attachments.success.attachmentUpload"),
          {
            variant: "success",
          },
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          i18next.t("mmRequests.attachments.errors.attachmentUpload"),
          {
            variant: "error",
          },
        );
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  useEffect(() => {
    if (mmRequestId) {
      fetchData();
    }
  }, [mmRequestId]);

  const fetchData = async () => {
    setIsFetchingAttachments(true);
    await fetchAttachments(mmRequestId)
      .then((response) => {
        if (response) {
          setAttachments(response);
        } else {
          setAttachments([]);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          i18next.t("mmRequests.attachments.errors.loadAttachments"),
          {
            variant: "error",
          },
        );
      })
      .finally(() => {
        setIsFetchingAttachments(false);
      });
  };

  const handleDelete = async (id: number) => {
    await deleteAttachments(id)
      .then((response) => {
        enqueueSnackbar(
          i18next.t("mmRequests.attachments.success.attachmentDelete"),
          {
            variant: "success",
          },
        );
        fetchData();
      })
      .catch((error) => {
        enqueueSnackbar(
          i18next.t("mmRequests.attachments.errors.attachmentDelete"),
          {
            variant: "error",
          },
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Controller
              name="file_attachments"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={(e) => {
                      const files = e.target.files || [];
                      setFileName(files[0]?.name || "");
                      field.onChange(files);
                    }}
                  />
                  <label htmlFor="file-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<Icon path={mdiPlus} size={1} />}
                    >
                      {fileName === ""
                        ? i18next.t("mmRequests.attachments.chooseFile")
                        : i18next.t(
                            "mmRequests.attachments.changeSelectedFile",
                          )}
                    </Button>
                  </label>
                </>
              )}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "flex-start" }}>
            {fileName === "" ? (
              <span>{i18next.t("mmRequests.attachments.noFileSelected")}</span>
            ) : (
              fileName
            )}
          </Box>

          <Button
            sx={{ alignSelf: "center" }}
            disabled={fileName === "" || loadingSubmit}
            variant="contained"
            type="submit"
            color="success"
            startIcon={
              loadingSubmit ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Icon path={mdiUpload} size={1} />
              )
            }
          >
            {i18next.t("mmRequests.attachments.upload")}
          </Button>
        </Box>
      </form>

      <Box sx={{ mt: "16px" }}>
        {isFetchingAttachments ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <AttachmentsTable
            attachments={attachments}
            handleDelete={handleDelete}
          />
        )}
      </Box>
    </>
  );
};

const AttachmentsTable = ({
  attachments,
  handleDelete,
}: {
  attachments: Attachment[];
  handleDelete: (id: number) => void;
}) => {
  const { user } = useAuth();

  if (!Array.isArray(attachments) || attachments.length === 0) {
    return (
      <Typography variant="body1" color="textSecondary">
        {i18next.t("mmRequests.attachments.noAttachments")}
      </Typography>
    );
  } else {
    return (
      <Table aria-label="Attachments List">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>{i18next.t("mmRequests.attachments.fileName")}</strong>
            </TableCell>
            <TableCell>
              <strong>{i18next.t("mmRequests.attachments.createdBy")}</strong>
            </TableCell>
            <TableCell>
              <strong>{i18next.t("mmRequests.attachments.uploadedAt")}</strong>
            </TableCell>
            <TableCell>
              <strong>{i18next.t("mmRequests.attachments.actions")}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, index) => (
            <TableRow key={index}>
              <TableCell>{attachment.filename}</TableCell>
              <TableCell>{attachment.mail_user}</TableCell>
              <TableCell>
                {attachment.created_at &&
                  i18next.t("dateTime", {
                    val: Date.parse(attachment.created_at),
                    interpolation: {
                      escapeValue: false,
                    },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      },
                    },
                  })}
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(attachment.url, "_blank")}
                  >
                    {" "}
                    <Icon path={mdiDownload} size={1} />
                  </Button>
                  {user?.id === attachment.uploaded_by && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(attachment.id)}
                    >
                      <Icon path={mdiDelete} size={1} />
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
};

export default DuimpRequestAttachments;
