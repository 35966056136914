import { MmRequestHeaderUpdateType } from "../@types/services/MmRequest";
import api from "./api";

export const fetchQuantityOfMaterialsOptions = async () => {
  const response = await api.get(`/mm_requests/quantity_of_materials_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchRequesterFieldOptions = async () => {
  const response = await api.get(`/mm_requests/requester_field_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchStatusOptions = async (id: number) => {
  const response = await api.get(`/mm_requests/${id}/status_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchQualityDeviationOptions = async () => {
  const response = await api.get(`/mm_requests/quality_deviation_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const updateMmRequestHeader = async (
  id: number,
  data: MmRequestHeaderUpdateType,
) => {
  const response = await api.put(`/mm_requests/${id}`, data, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const replicateRequest = async (id: number) => {
  const response = await api.post(
    `/mm_requests/${id}/replicate_request`,
    {},
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};

export const addAttachments = async (file_attachments: File[], id: string) => {
  const formData = new FormData();
  Array.from(file_attachments).forEach((file) => {
    formData.append("file_attachments[]", file);
  });

  await api.postForm(`/mm_requests/${id}/attach_file`, formData, {
    baseURL: "/vbuyer/api/v2",
  });
};

export const fetchAttachments = async (id: number) => {
  const response = await api.get(`/mm_requests/${id}/list_attachments`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const deleteAttachments = async (id: number) => {
  const response = await api.delete(`/attachments/${id}`, {
    baseURL: "/api/v2",
  });
  return response.data;
};

export const fetchHistoryLog = async (id: number) => {
  const response = await api.get(`/mm_requests/${id}/request_history_log`, {
    baseURL: "/vbuyer/api/v2",
  });

  return response.data;
};
