import {
  DuimpRequest,
  DuimpRequestDetailsUpdateType,
} from "../@types/services/DuimpRequest";
import api from "./api";
import { addAttachments } from "./mm_requests";

export const fetchDuimpRequest = async (id: string) => {
  const response = await api.get(`/duimp_requests/${id}`, {
    baseURL: "/vbuyer/api/v2",
  });

  return response.data;
};

export const createDuimpRequest = async (
  duimpRequest: DuimpRequest,
  file_attachments: File[],
) => {
  const response = await api.post(
    `/duimp_requests`,
    { duimp_request: duimpRequest },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  if (response.data.id && file_attachments.length > 0) {
    await addAttachments(file_attachments, response.data.mm_request.id);
  }

  return response.data;
};

export const updateDuimpRequest = async (
  id: string,
  duimpRequest: DuimpRequestDetailsUpdateType,
) => {
  const response = await api.put(
    `/duimp_requests/${id}`,
    { duimp_request: duimpRequest },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
