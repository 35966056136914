import { Box, Button, Modal, Typography } from "@mui/material";

interface ConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleOk: () => void;
  title: string;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  setOpen,
  handleOk,
  title,
  message,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          borderRadius: 1,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={() => setOpen(false)} color="primary" sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" variant="contained">
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
