import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import { SnackbarProvider, useSnackbar } from "notistack";
import { FC, ReactElement, Suspense, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import SymbolRedLoading from "../images/equinor-symbol-red-loading.gif";
import LeftMenuInterface from "./@types/components/organisms/LeftMenu";
import CookieConsent from "./components/molecules/CookieConsent";
import LeftMenu from "./components/organisms/LeftMenu";
import TopMenu from "./components/organisms/TopMenu";
import ThemeModeContext from "./contexts/ThemeMode";
import useAuth from "./hooks/useAuth";
import i18next from "./i18n";
import AppProvider from "./providers/AppProvider";
import ThemeModeProvider from "./providers/ThemeMode";
import AppRoutes from "./routes";

const FullScreenLoading: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "rgba(248, 249, 250)",
        zIndex: 2000,
      }}
    >
      <img
        src={SymbolRedLoading}
        alt="Loading..."
        style={{ height: "100px", margin: "1.5rem" }}
      />

      <h1 style={{ fontSize: "36px", color: "rgb(108, 117, 125)" }}>
        {i18next.t("pageLoading.title")}
      </h1>

      <h3 style={{ color: "rgb(108, 117, 125)" }}>
        {i18next.t("pageLoading.message")}
      </h3>
    </Box>
  );
};

const MainApp: FC<{
  leftMenu: LeftMenuInterface[];
}> = ({ leftMenu }): ReactElement => {
  const { user, loading } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [leftMenuOpen, setLeftMenuOpen] = useState(true);

  if (loading) {
    enqueueSnackbar("Loading user");
    return <p>Loading user</p>;
  }

  return (
    <>
      {user && <TopMenu setLeftMenuOpen={setLeftMenuOpen} />}
      <Box sx={{ display: "flex" }}>
        {user && (
          <LeftMenu
            options={leftMenu}
            leftMenuOpen={leftMenuOpen}
            setLeftMenuOpen={setLeftMenuOpen}
          />
        )}
        <AppRoutes />
      </Box>
      <CookieConsent />
    </>
  );
};

const queryClient = new QueryClient();

const App: FC<{ leftMenu: LeftMenuInterface[] }> = ({
  leftMenu,
}): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeModeProvider>
        <ThemeModeContext.Consumer>
          {({ theme }) => (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider>
                <Suspense fallback={<FullScreenLoading />}>
                  <BrowserRouter>
                    <AppProvider>
                      <I18nextProvider i18n={i18next}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next.language}
                        >
                          <MainApp leftMenu={leftMenu} />
                        </LocalizationProvider>
                      </I18nextProvider>
                    </AppProvider>
                  </BrowserRouter>
                </Suspense>
              </SnackbarProvider>
            </ThemeProvider>
          )}
        </ThemeModeContext.Consumer>
      </ThemeModeProvider>
    </QueryClientProvider>
  );
};

export { App as default, FullScreenLoading };
