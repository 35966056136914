import axios, { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { SignInCredentials } from "../@types/contexts/Auth/AuthContext";
import User from "../@types/services/User";
import { FullScreenLoading } from "../App";
import config from "../config";
import AuthContext from "../contexts/Auth/AuthContext";
import api from "../services/api";

const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  // const logoutLink = onError(({ networkError }) => { // TODO: GraphQL
  //   const networkErrorTyped = networkError as ServerError;
  //   if ([401, 403].includes(networkErrorTyped?.statusCode as number)) {
  //     signOut();
  //   }
  // });

  // client.setLink(logoutLink.concat(clientLink)); // TODO: GraphQL

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  // const [getUser, { loading: loadingUser, error, data: userData }] = useLazyQuery<Data>(GET_USER); // TODO: GraphQL
  const error = false; // TODO: Remove this when implement GraphQL
  const loadingUser = false; // TODO: Remove this when implement GraphQL

  const signIn = useCallback(
    async ({ email, password, token }: SignInCredentials) => {
      // Temporary trigger login in session
      const bodyFormData = new FormData();
      bodyFormData.append("user[login]", email);
      bodyFormData.append("user[password]", password);
      bodyFormData.append("g-recaptcha-response", token);
      await axios
        .post("/users/sign_in", bodyFormData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          xsrfCookieName: "SELLTO-CSRF-TOKEN",
          xsrfHeaderName: "X-CSRF-Token",
        })
        .catch((axiosError: AxiosError) => {
          console.log(axiosError);

          const response =
            (axiosError?.response?.data as {
              status: boolean;
              errors: Array<string>;
              error: string;
            }) || undefined;
          response?.errors?.forEach((message) => {
            enqueueSnackbar(message);
          });
          if (response?.error) {
            enqueueSnackbar(response?.error);
          }

          return Promise.reject(axiosError);
        });

      // const responseData: { user?: User } = await api
      //   .post(config.auth.signIn, {
      //     login: email,
      //     password,
      //   })
      //   .then(({ data }: AxiosResponse<any>) => {
      //     return {
      //       user: data.data,
      //     };
      //   })
      //   .catch((axiosError: AxiosError) => {
      //     console.log("catch axiosError");
      //     console.log(axiosError);

      //     const response =
      //       (axiosError?.response?.data as {
      //         status: boolean;
      //         errors: Array<string>;
      //       }) || undefined;
      //     response?.errors.forEach((message) => {
      //       enqueueSnackbar(message);
      //     });

      //     return Promise.reject(axiosError);
      //   });

      const responseData: { user?: User; valid?: boolean } = await api
        .get(config.auth.validateToken)
        .then(({ data }: AxiosResponse<any>) => {
          return {
            user: data.data,
            valid: data.success,
          };
        })
        .catch((axiosError: AxiosError) => {
          console.log(axiosError);
          signOut();

          return Promise.reject(axiosError);
        });

      if (!responseData) {
        return;
      }

      if (typeof responseData.user === "object") {
        const dataUser = responseData.user;
        setUser(() => dataUser);
      }
    },
    [enqueueSnackbar],
  );

  const signOut = useCallback(async () => {
    // const p1 = api
    //   .delete(config.auth.signOut)
    //   .catch((axiosError: AxiosError) => {
    //     console.log(axiosError);
    //   });
    // const p2 = axios.get("/users/sign_out").catch((axiosError: AxiosError) => {
    //   console.log(axiosError);
    // });
    // const p3 = axios // Temporary disable DELETE sign_out because that make a DELETE to redirected route
    //   .delete("/users/sign_out")
    //   .catch((axiosError: AxiosError) => {
    //     console.log(axiosError);
    //   });

    const p3 = fetch("/users/sign_out", {
      // Temporary disable DELETE sign_out because that make a DELETE to redirected route
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }).catch((error) => {
      console.log(error);
    });

    // await Promise.all([p1, p2, p3]);
    // await Promise.all([p1, p2]);
    // await Promise.all([p1]);
    await Promise.all([p3]);

    setUser(() => undefined);
    setLoading(() => false);

    enqueueSnackbar("Sign Out ...");

    navigate("/users/sign_in", { replace: true });
  }, [enqueueSnackbar, navigate]);

  const refreshUser = useCallback(
    async (forceRefresh = false) => {
      if (user && !forceRefresh) {
        return;
      }

      const responseDataSession: { valid?: boolean } = await axios
        .get("/user/session_is_valid")
        .then(({ data }: AxiosResponse<any>) => {
          return {
            valid: !!data.success,
          };
        })
        .catch((axiosError: AxiosError) => {
          if (axiosError.response?.status === 401) {
            return {
              valid: false,
            };
          }
          console.log(axiosError);
          signOut();

          return Promise.reject(axiosError);
        });

      const responseData: { user?: User; valid?: boolean } = await api
        .get(config.auth.validateToken)
        .then(({ data }: AxiosResponse<any>) => {
          return {
            user: data.data,
            valid: data.success,
          };
        })
        .catch((axiosError: AxiosError) => {
          if (axiosError.response?.status === 401) {
            return {
              valid: false,
            };
          }
          console.log(axiosError);
          signOut();

          return Promise.reject(axiosError);
        });

      if (!responseData?.valid || !responseDataSession?.valid) {
        signOut();
        return;
      }

      if (typeof responseData?.user === "object") {
        const dataUser = responseData.user;
        setUser(() => dataUser);
      }
    },
    [signOut, user],
  );

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  useEffect(() => {
    if (!loadingUser && (user || error)) {
      setLoading(() => false);
    }
  }, [user, error, loadingUser]);

  // useEffect(() => { // TODO: GraphQL
  //   setUser(() => userData?.user);
  // }, [userData]);

  const providerValues = useMemo(() => {
    return {
      user,
      loading: loading || loadingUser,
      // error, // TODO: GraphQL
      signIn,
      signOut,
      refreshUser,
    };
  }, [user, loading, loadingUser, signIn, signOut, refreshUser]);

  if (loading || loadingUser) {
    return <FullScreenLoading />;
  }

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
