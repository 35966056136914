import { mdiContentSaveOutline } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { MmRequestHeaderType } from "../../../@types/services/MmRequest";
import User from "../../../@types/services/User";
import useAuth from "../../../hooks/useAuth";
import {
  fetchStatusOptions,
  updateMmRequestHeader,
} from "../../../services/mm_requests";
import { fetchUsers } from "../../../services/users";

type formValues = {
  status: string;
  requester: User;
  backup_requester: User | null;
  assigned_to: User;
  issue_owner: User;
  watchers: User[];
  comments: string;
  private: boolean;
};

const MmRequestHeader: React.FC<MmRequestHeaderType> = (props) => {
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields },
    reset,
  } = useForm<formValues>({
    defaultValues: {
      status: props.status.name,
      requester: props.requester,
      backup_requester: props.backup_requester,
      assigned_to: props.assigned_to,
      issue_owner: props.issue_owner,
      watchers: props.watchers,
      comments: "",
      private: false,
    },
  });

  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [backupRequesterLoading, setBackupRequesterLoading] = useState(false);
  const [backupRequesterOptions, setBackupRequesterOptions] =
    useState<User[]>();
  const [assignedToLoading, setAssignedToLoading] = useState(false);
  const [assignedToOptions, setAssignedToOptions] = useState<User[]>([]);
  const [issueOwnerLoading, setIssueOwnerLoading] = useState(false);
  const [issueOwnerOptions, setIssueOwnerOptions] = useState<User[]>([]);
  const [watchersLoading, setWatchersLoading] = useState(false);
  const [watcherOptions, setWatchersOptions] = useState<User[]>([]);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const watchedValues = useWatch({ control });

  const { user } = useAuth();

  const requiredFields = {
    status: true,
    requester: true,
    backup_requester: false,
    assigned_to: true,
    issue_owner: true,
    watchers: false,
    comments: false,
    private: false,
  };

  const isFieldRequired = (fieldName: keyof typeof requiredFields) => {
    return requiredFields[fieldName] || false;
  };

  const fieldRules = {
    status: {
      required: true,
      canEdit:
        !props.completed &&
        (user?.user_groups_names?.includes("Materials Management") ||
          (props.reason === "MRD" &&
            user?.user_groups_names?.includes(
              "Materials Management Repetro Control",
            ))),
    },
    requester: { required: false, canEdit: false },
    backup_requester: {
      required: false,
      canEdit:
        !props.completed &&
        (user?.user_groups_names?.includes("Materials Management") ||
          (props.reason === "MRD" &&
            user?.user_groups_names?.includes(
              "Materials Management Repetro Control",
            ))),
    },
    assigned_to: {
      required: true,
      canEdit:
        !props.completed &&
        (user?.user_groups_names?.includes("Materials Management") ||
          (props.reason === "MRD" &&
            user?.user_groups_names?.includes(
              "Materials Management Repetro Control",
            )) ||
          user?.id == props.assigned_to.id),
    },
    issue_owner: {
      required: true,
      canEdit:
        !props.completed &&
        (user?.user_groups_names?.includes("MM - Manager") ||
          (props.reason === "MRD" &&
            user?.user_groups_names?.includes(
              "Materials Management Repetro Control",
            ))),
    },
    watchers: { required: false, canEdit: !props.completed },
    comments: { required: false, canEdit: !props.completed },
    private: { required: false, canEdit: !props.completed },
  };

  const handleFetchUsers = async (
    fieldName: keyof formValues,
    setOptions: (user: User[]) => void,
    setLoading: (value: boolean) => void,
    filters?: any,
  ) => {
    if (filters && filters?.name_or_login?.length >= 2) {
      setLoading(true);
      await fetchUsers({
        filters,
      })
        .then((response) => {
          if (response.results) {
            const selectedOptions = getValues(fieldName);
            if (Array.isArray(selectedOptions)) {
              const combinedOptions = [
                ...response.results.filter(
                  (item: { id: number }) =>
                    !selectedOptions.some(
                      (selected) => selected.id === item.id,
                    ),
                ),
                ...selectedOptions,
              ];
              setOptions(combinedOptions);
            } else {
              setOptions(response.results);
            }
          } else {
            const fieldValue = getValues(fieldName);
            setOptions(Array.isArray(fieldValue) ? fieldValue : []);
          }
        })
        .catch((error) => {
          enqueueSnackbar(i18next.t("mmRequests.header.error.fetch_users"), {
            variant: "error",
          });
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusOptions(props.id).then((response) => {
      setStatusOptions(response);
    });
  }, [props.id]);

  const onSubmit: SubmitHandler<formValues> = async (data) => {
    setFormIsSubmitting(true);
    const formattedData = {
      mm_request: {
        status: data.status,
        backup_requester_id: data.backup_requester
          ? data.backup_requester.id
          : null,
        assigned_to: data.assigned_to.id,
        issue_owner_id: data.issue_owner.id,
        watcher:
          data.watchers.length === 0
            ? [""]
            : data.watchers.map((watcher) => watcher.id),
      },
      comments: data.comments,
      private: data.private,
    };
    await updateMmRequestHeader(props.id, formattedData).then(
      (response: any) => {
        reset({
          status: response.status.name,
          requester: data.requester,
          backup_requester: response.backup_requester
            ? response.backup_requester
            : null,
          assigned_to: response.assigned_to,
          issue_owner: response.issue_owner,
          watchers: response.watcher_users,
          comments: "",
          private: false,
        });
        enqueueSnackbar(
          i18next.t("mmRequests.header.success.request_updated"),
          { variant: "success" },
        );
      },
    );
    setFormIsSubmitting(false);
  };

  useEffect(() => {
    if (isDirty && getDirtyFields().length > 0) {
      setFormHasChanged(true);
    } else {
      setFormHasChanged(false);
    }
  }, [isDirty, watchedValues]);

  const getDirtyFields = () => {
    return Object.keys(dirtyFields).filter(
      (field) => dirtyFields[field as keyof formValues],
    );
  };

  return (
    <Paper
      sx={{
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={statusOptions}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={i18next.t("mmRequests.header.status")}
                    size="small"
                    error={!!errors.status}
                    helperText={
                      errors.status
                        ? i18next.t("mmRequests.header.error.status_required")
                        : ""
                    }
                  />
                )}
                onChange={(_, value) => field.onChange(value)}
                disabled={!fieldRules.status.canEdit}
              />
            )}
            rules={{ required: fieldRules.status.required }}
          />
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Controller
              name="requester"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={i18next.t("mmRequests.header.requester")}
                  size="small"
                  error={!!errors.requester}
                  helperText={
                    errors.requester
                      ? i18next.t("mmRequests.header.error.requester_required")
                      : ""
                  }
                  value={props.requester.full_name_with_login}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={!fieldRules.requester.canEdit}
                />
              )}
              rules={{ required: fieldRules.requester.required }}
            />
            <Controller
              name="backup_requester"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={{ width: "100%" }}
                  loading={backupRequesterLoading}
                  options={backupRequesterOptions || []}
                  getOptionLabel={(option) =>
                    option.full_name_with_login ||
                    `${option.first_name} ${option.last_name} - ${option.login}` ||
                    ""
                  }
                  onChange={(_, value) => field.onChange(value)}
                  noOptionsText={i18next.t("mmRequests.header.noOptionsText")}
                  onInputChange={(event, value) => {
                    if (event) {
                      // Evita que a função seja chamada ao carregar a página
                      handleFetchUsers(
                        field.name,
                        setBackupRequesterOptions,
                        setBackupRequesterLoading,
                        {
                          name_or_login: value,
                          user_groups: {
                            name: ["MM Requester"],
                          },
                          material_management_user: true,
                        },
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("mmRequests.header.backup_requester")}
                      size="small"
                      error={!!errors.backup_requester}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {backupRequesterLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        },
                      }}
                    />
                  )}
                  disabled={!fieldRules.backup_requester.canEdit}
                />
              )}
              rules={{ required: fieldRules.backup_requester.required }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
            <Controller
              name="assigned_to"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={{ width: "100%" }}
                  options={assignedToOptions}
                  getOptionLabel={(option) => option.full_name_with_login || ""}
                  onChange={(_, value) => field.onChange(value)}
                  noOptionsText={i18next.t("mmRequests.header.noOptionsText")}
                  onInputChange={(event, value) => {
                    if (event) {
                      // Evita que a função seja chamada ao carregar a página
                      handleFetchUsers(
                        field.name,
                        setAssignedToOptions,
                        setAssignedToLoading,
                        {
                          name_or_login: value,
                          user_groups: {
                            name: [
                              "Materials Management",
                              "Materials Management Repetro Control",
                            ],
                          },
                          material_management_user: true,
                        },
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("mmRequests.header.assigned_to")}
                      size="small"
                      helperText={
                        errors.assigned_to
                          ? i18next.t(
                              "mmRequests.header.errors.assigned_to_required",
                            )
                          : ""
                      }
                      error={!!errors.assigned_to}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {assignedToLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        },
                      }}
                    />
                  )}
                  disabled={!fieldRules.assigned_to.canEdit}
                />
              )}
              rules={{ required: fieldRules.assigned_to.required }}
            />

            <Controller
              name="issue_owner"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={issueOwnerOptions}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.full_name_with_login || ""}
                  onChange={(_, value) => field.onChange(value)}
                  noOptionsText={i18next.t("mmRequests.header.noOptionsText")}
                  onInputChange={(event, value) => {
                    if (event) {
                      // Evita que a função seja chamada ao carregar a página
                      handleFetchUsers(
                        field.name,
                        setIssueOwnerOptions,
                        setIssueOwnerLoading,
                        {
                          name_or_login: value,
                          material_management_user: true,
                        },
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("mmRequests.header.issue_owner")}
                      size="small"
                      helperText={
                        errors.issue_owner
                          ? i18next.t(
                              "mmRequests.header.errors.issue_owner_required",
                            )
                          : ""
                      }
                      error={!!errors.issue_owner}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {issueOwnerLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        },
                      }}
                    />
                  )}
                  disabled={!fieldRules.issue_owner.canEdit}
                />
              )}
              rules={{ required: fieldRules.issue_owner.required }}
            />
          </Box>

          <Controller
            name="watchers"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={watcherOptions} // Substitua com suas opções reais
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.full_name_with_login || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={field.value || []}
                onChange={(_, value) => field.onChange(value)}
                noOptionsText={i18next.t("mmRequests.header.noOptionsText")}
                onInputChange={(event, value) => {
                  if (event) {
                    // Evita que a função seja chamada ao carregar a página
                    handleFetchUsers(
                      field.name,
                      setWatchersOptions,
                      setWatchersLoading,
                      {
                        name_or_login: value,
                        material_management_user: true,
                      },
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.inputProps.id}
                    label={i18next.t("mmRequests.header.watchers")}
                    size="small"
                    helperText={
                      errors.watchers
                        ? i18next.t(
                            "mmRequests.header.errors.watchers_required",
                          )
                        : ""
                    }
                    error={!!errors.watchers}
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {watchersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      },
                    }}
                  />
                )}
                disabled={!fieldRules.watchers.canEdit}
              />
            )}
            rules={{ required: fieldRules.watchers.required }}
          />
          {!props.completed && (
            <>
              <Divider sx={{ margin: "8px 0px" }} />

              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={i18next.t("mmRequests.header.comments")}
                    size="small"
                    multiline
                    rows={4}
                    error={!!errors.comments}
                  />
                )}
                rules={{ required: isFieldRequired("comments") }}
              />

              {user?.user_groups_names?.includes("Materials Management") && (
                <Controller
                  name="private"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={i18next.t("mmRequests.header.private")}
                      sx={{ alignSelf: "flex-start" }}
                    />
                  )}
                  rules={{ required: isFieldRequired("private") }}
                />
              )}

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={formIsSubmitting || !formHasChanged}
                startIcon={
                  formIsSubmitting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Icon path={mdiContentSaveOutline} size={1} />
                  )
                }
                sx={{ alignSelf: "center" }}
              >
                {i18next.t("mmRequests.header.save")}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Paper>
  );
};

export default MmRequestHeader;
