import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { HistoryLogType } from "../../../@types/services/HistoryLog";
import useAuth from "../../../hooks/useAuth";
import { fetchHistoryLog } from "../../../services/mm_requests";

const MmHistoryLogs = ({ mmRequestId }: { mmRequestId: number }) => {
  const { user } = useAuth();

  const [historyLogs, setHistoryLogs] = useState<HistoryLogType | null>(null);

  useEffect(() => {
    fetchHistoryLog(mmRequestId)
      .then((response) => {
        setHistoryLogs(response);
      })
      .catch((error) => {});
  }, [mmRequestId]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{i18next.t("mmRequests.historyLogs.date")}</TableCell>
            <TableCell>
              {i18next.t("mmRequests.historyLogs.updatedBy")}
            </TableCell>
            <TableCell>{i18next.t("mmRequests.historyLogs.action")}</TableCell>
            <TableCell>
              {i18next.t("mmRequests.historyLogs.activity")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyLogs &&
            historyLogs.map((log, index) => {
              if ("act_action" in log) {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {i18next.t("dateTime", {
                        val: Date.parse(log.act_tstamp),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          },
                        },
                      })}
                    </TableCell>
                    <TableCell>{log.updated_by}</TableCell>
                    <TableCell>
                      {i18next.t(
                        `mmRequests.historyLogs.actions.${log.act_action}`,
                      )}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        const logObject = JSON.parse(log.activity);
                        const key = Object.keys(logObject)[0];
                        const values = logObject[key];
                        return (
                          <>
                            <span style={{ fontWeight: "bold" }}>{key}</span>
                            {` ${i18next.t("mmRequests.historyLogs.changedFrom")} `}
                            <span style={{ fontWeight: "bold" }}>
                              {values[0] || "N/A"}
                            </span>
                            {` ${i18next.t("mmRequests.historyLogs.changedTo")} `}
                            <span style={{ fontWeight: "bold" }}>
                              {values[1] || "N/A"}
                            </span>
                          </>
                        );
                      })()}
                    </TableCell>
                  </TableRow>
                );
              } else if (
                "comment" in log &&
                (log?.private === false ||
                  user?.user_groups_names?.includes("Materials Management"))
              ) {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {i18next.t("dateTime", {
                        val: Date.parse(log.updated_at),
                        interpolation: {
                          escapeValue: false,
                        },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          },
                        },
                      })}
                    </TableCell>
                    <TableCell>{log.author_full_name_with_login}</TableCell>
                    <TableCell>
                      {i18next.t("mmRequests.historyLogs.actions.comment")}
                    </TableCell>
                    <TableCell>{log.comment}</TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MmHistoryLogs;
