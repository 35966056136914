import { FC, ReactElement, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { checkMandatoryTerms } from "../../services/users";

const ProtectedRoute: FC<{
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement | null;
}> = ({
  isAllowed,
  redirectPath = "/users/sign_in", // TODO: Probably the route we want to redirect will be "/", but temporarily changed to "sign_in"
  children = null,
}): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (isAllowed && user && user.vendor) {
      checkMandatoryTerms().then((response) => {
        if (response && response.missing_terms) {
          navigate("/vsupplierregistration/vendor/terms");
        }
      });
    }
  }, [isAllowed, navigate]);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
