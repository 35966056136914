import {
  mdiCircleMultipleOutline,
  mdiHistory,
  mdiInformation,
  mdiPaperclip,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../@types/services";
import { DuimpRequestDetailsType } from "../../@types/services/DuimpRequest";
import { MmRequestHeaderType } from "../../@types/services/MmRequest";
import DuimpRequestDetails from "../../components/molecules/DuimpRequestForm/details";
import MmHistoryLogs from "../../components/molecules/MmHistoryLogs";
import DuimpRequestAttachments from "../../components/molecules/MmRequestAttachments";
import MmRequestHeader from "../../components/molecules/MmRequestHeader";
import ConfirmationModal from "../../components/molecules/MmRequestHeader/ConfirmationModal";
import { fetchDuimpRequest } from "../../services/duimpRequests";

const ShowDuimpRequest = () => {
  const navigate = useNavigate();
  const { id: duimpRequestId } = useParams<{ id: string }>();

  const [requestHeaderData, setRequestHeaderData] =
    useState<MmRequestHeaderType>();
  const [mmRequestId, setMmRequestId] = useState<number | null>(null);
  const [requestDetailsData, setRequestDetailsData] =
    useState<DuimpRequestDetailsType>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  useEffect(() => {
    if (!duimpRequestId) return;
    setIsFetchingData(true);
    fetchDuimpRequest(duimpRequestId)
      .then((response) => {
        setMmRequestId(response.mm_request.id);
        console.log("mmRequestId", mmRequestId);
        setRequestHeaderData({
          reason: response.mm_request.reason,
          id: response.mm_request.id,
          status: response.mm_request.status,
          requester: response.mm_request.requester,
          backup_requester: response.mm_request.backup_requester || null,
          assigned_to: response.mm_request.assigned_to,
          issue_owner: response.mm_request.issue_owner,
          watchers: response.mm_request.watchers.map(
            (item: { watcher: User }) => item.watcher,
          ),
          completed: response.mm_request.process_instance.completed,
        });

        setRequestDetailsData({
          id: duimpRequestId,
          mm_request: {
            id: response.mm_request.id,
            created_at: response.mm_request.created_at,
            updated_at: response.mm_request.updated_at,
            deadline: response.mm_request.deadline,
            threat: response.mm_request.threat,
            requester_field: response.mm_request.requester_field,
            quantity_of_materials: response.mm_request.quantity_of_materials,
            matnumber: response.mm_request.matnumber,
            subject: response.mm_request.subject,
            description: response.mm_request.description,
            quality_deviation: response.mm_request.quality_deviation.filter(
              (element: string) => element !== "",
            ),
            quality_deviation_description:
              response.mm_request.quality_deviation_description,
            assigned_to: response.mm_request.assigned_to,
            completed: response.mm_request.process_instance.completed,
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(i18next.t("mmRequests.fetchError"), {
          variant: "error",
        });
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleCopyRequest = () => {
    const prefilledData = {
      mm_request_id: mmRequestId,
      requester_field: requestDetailsData?.mm_request.requester_field,
      quantity_of_materials:
        requestDetailsData?.mm_request.quantity_of_materials,
      matnumber: requestDetailsData?.mm_request.matnumber?.map((matnumber) => ({
        matnumber: matnumber,
      })),
      subject: requestDetailsData?.mm_request.subject,
      description: requestDetailsData?.mm_request.description,
    };
    setConfirmModalOpen(false);
    navigate("/vbuyer/duimp_requests/new", { state: { prefilledData } });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {isFetchingData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="div">
              {"DUIMP REQUEST" + " #" + mmRequestId}
            </Typography>
          </Paper>

          {requestHeaderData && <MmRequestHeader {...requestHeaderData} />}

          <ConfirmationModal
            open={confirmModalOpen}
            setOpen={setConfirmModalOpen}
            handleOk={handleCopyRequest}
            title={i18next.t("mmRequests.copyModalTitle")}
            message={i18next.t("mmRequests.copyModalMessage")}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="contained"
              onClick={() => {
                setConfirmModalOpen(true);
              }}
              startIcon={<Icon path={mdiCircleMultipleOutline} size={1} />}
            >
              {i18next.t("mmRequests.copyButton")}
            </Button>
          </Box>

          <Paper sx={{ padding: "24px" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={{ alignSelf: "flex-start" }}
            >
              <Tab
                icon={<Icon path={mdiInformation} size={1} />}
                iconPosition="start"
                label={i18next.t("mmRequests.basicInformation")}
              />
              <Tab
                icon={<Icon path={mdiPaperclip} size={1} />}
                iconPosition="start"
                label={i18next.t("mmRequests.attachmentsTabTitle")}
              />
              <Tab
                icon={<Icon path={mdiHistory} size={1} />}
                iconPosition="start"
                label={i18next.t("mmRequests.historyLogsTabTitle")}
              />
            </Tabs>
            <Box sx={{ marginTop: "32px" }}>
              {tabIndex === 0 && requestDetailsData && (
                <DuimpRequestDetails {...requestDetailsData} />
              )}
              {tabIndex === 1 && mmRequestId && (
                <DuimpRequestAttachments mmRequestId={mmRequestId} />
              )}
              {tabIndex === 2 && mmRequestId && (
                <MmHistoryLogs mmRequestId={mmRequestId} />
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default ShowDuimpRequest;
