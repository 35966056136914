import { User } from "../@types/services";
import api from "./api";

export type UsersFilter = {
  email?: string;
  purchase_group?: number;
  user_group_name?: string;
  name_or_login?: string;
  material_management_user?: boolean;
  id?: number;
};

interface UsersFetch {
  filters?: UsersFilter;
}

interface UsersResponse {
  total_items: number;
  results: User[] | null;
}

export const fetchUsers = async ({
  filters,
}: UsersFetch): Promise<UsersResponse> => {
  const response = await api.get<UsersResponse>("/users", {
    baseURL: "/api/v2",
    params: {
      ...filters,
    },
  });

  return response.data;
};

export const checkMandatoryTerms = async (): Promise<{
  missing_terms: boolean;
}> => {
  const response = await api.get<{ missing_terms: boolean }>(
    `/users/check_terms`,
    {
      baseURL: "/api/v2",
    },
  );

  return response.data;
};
