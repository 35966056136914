import { mdiContentCopy, mdiContentSaveOutline, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Fab,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { DuimpRequestDetailsType } from "../../../@types/services/DuimpRequest";
import useAuth from "../../../hooks/useAuth";
import { updateDuimpRequest } from "../../../services/duimpRequests";
import { fetchMaterials } from "../../../services/materials";
import {
  fetchQualityDeviationOptions,
  fetchQuantityOfMaterialsOptions,
  fetchRequesterFieldOptions,
} from "../../../services/mm_requests";
import ConfirmationModal from "../MmRequestHeader/ConfirmationModal";

type formValues = {
  requester_field: string;
  quantity_of_materials: string;
  matnumber: string[];
  subject: string;
  description: string;
  quality_deviation: string[];
  quality_deviation_description: string;
};

const DuimpRequestDetails: React.FC<DuimpRequestDetailsType> = (props) => {
  const {
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<formValues>({
    defaultValues: {
      requester_field: props.mm_request.requester_field,
      quantity_of_materials: props.mm_request.quantity_of_materials,
      matnumber: props.mm_request.matnumber,
      subject: props.mm_request.subject,
      description: props.mm_request.description,
      quality_deviation: props.mm_request.quality_deviation,
      quality_deviation_description:
        props.mm_request.quality_deviation_description,
    },
  });

  const watchedValues = useWatch({ control });

  const { user } = useAuth();

  const [quantityOfMaterialOptions, setQuantityOfMaterialOptions] = useState<
    string[]
  >([]);
  const [requesterFieldOptions, setRequesterFieldOptions] = useState<string[]>(
    [],
  );
  const [qualityDeviationOptions, setQualityDeviationOptions] = useState<
    string[]
  >([]);

  const [materials, setMaterials] = useState<string[]>([]);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [formIsEditing, setFormIsEditing] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formData, setFormData] = useState<formValues>();
  const [userCanEdit, setUserCanEdit] = useState(false);

  useEffect(() => {
    if (!user) return;
    setUserCanEdit(
      (user.roles_names.includes("Edit material requests") ||
        user.id.toString() == props.mm_request.assigned_to) &&
        !props.mm_request.completed,
    );
  }, [user]);

  const fieldRules = {
    deadline: { required: false },
    threat: { required: false },
    requester_field: { required: false },
    quantity_of_materials: { required: false },
    matnumber: { required: true },
    subject: { required: false },
    description: { required: false },
    quality_deviation: { required: false },
    quality_deviation_description: { required: false },
  };

  const handleFetchMaterials = useCallback(async (filters?: any) => {
    if (filters && filters?.material_number?.length >= 3) {
      setMaterialsLoading(true);
      await fetchMaterials({
        filters,
      })
        .then((response) => {
          if (response.results) {
            const selectedMaterials = getValues("matnumber");
            if (selectedMaterials) {
              const combinedMaterials = [
                ...response.results
                  .map((item: { matnumber: string }) => item.matnumber)
                  .filter(
                    (matnumber: string) =>
                      !selectedMaterials.includes(matnumber),
                  ),
                ...selectedMaterials,
              ];
              setMaterials(combinedMaterials);
            } else {
              setMaterials(
                response.results.map(
                  (item: { matnumber: string }) => item.matnumber,
                ),
              );
            }
          } else {
            setMaterials(getValues("matnumber"));
          }
        })
        .catch((error) => {
          enqueueSnackbar(i18next.t("duimpRequests.matnumberFetchError"), {
            variant: "error",
          });
        });
      setMaterialsLoading(false);
    }
  }, []);

  const copyToClipboard = (materials: string[]) => {
    const formattedText = materials.join("\n");
    navigator.clipboard.writeText(formattedText).then(() => {
      enqueueSnackbar(i18next.t("duimpRequests.copyMatnumber"), {
        variant: "info",
      });
    });
  };

  useEffect(() => {
    fetchQuantityOfMaterialsOptions().then((data) => {
      setQuantityOfMaterialOptions(data);
    });
    fetchRequesterFieldOptions().then((data) => {
      setRequesterFieldOptions(data);
    });
    fetchQualityDeviationOptions().then((data) => {
      setQualityDeviationOptions(data);
    });
  }, []);

  const onSubmit: SubmitHandler<formValues> = async (data) => {
    if (!formIsEditing) {
      if (userCanEdit) {
        setFormIsEditing(true);
      }
      return;
    }

    setFormData(data);
    setFormIsSubmitting(true);
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    if (isDirty && getDirtyFields().length > 0) {
      setFormHasChanged(true);
    } else {
      setFormHasChanged(false);
    }
  }, [isDirty, watchedValues]);

  const getDirtyFields = () => {
    return Object.keys(dirtyFields).filter(
      (field) => dirtyFields[field as keyof formValues],
    );
  };

  const handleSubmitAfterConfirmation = async () => {
    setConfirmModalOpen(false);
    if (!formData) return;

    const formattedData = {
      mm_request_attributes: {
        id: props.mm_request.id,
        requester_field: formData.requester_field,
        quantity_of_materials: formData.quantity_of_materials,
        matnumber: formData.matnumber,
        subject: formData.subject,
        description: formData.description,
        quality_deviation: formData.quality_deviation,
        quality_deviation_description: formData.quality_deviation_description,
      },
    };

    await updateDuimpRequest(props.id, formattedData).then((response) => {
      reset({
        requester_field: response.mm_request.requester_field,
        quantity_of_materials: response.mm_request.quantity_of_materials,
        matnumber: response.mm_request.matnumber,
        subject: response.mm_request.subject,
        description: response.mm_request.description,
        quality_deviation: response.mm_request.quality_deviation,
        quality_deviation_description:
          response.mm_request.quality_deviation_description,
      });
      props.mm_request.created_at = response.mm_request.created_at;
      props.mm_request.updated_at = response.mm_request.updated_at;
      props.mm_request.deadline = response.mm_request.deadline;
      props.mm_request.threat = response.mm_request.threat;

      enqueueSnackbar(i18next.t("duimpRequests.requestUpdated"), {
        variant: "success",
      });
    });

    setFormIsSubmitting(false);
    setFormIsEditing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "16px" }}>
            <TextField
              fullWidth
              label={i18next.t("duimpRequests.createdAt")}
              size="small"
              value={
                props.mm_request.updated_at &&
                i18next.t("dateTime", {
                  val: Date.parse(props.mm_request.created_at),
                  interpolation: {
                    escapeValue: false,
                  },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    },
                  },
                })
              }
              disabled
            />
            <TextField
              fullWidth
              label={i18next.t("duimpRequests.updatedAt")}
              size="small"
              value={
                props.mm_request.updated_at &&
                i18next.t("dateTime", {
                  val: Date.parse(props.mm_request.updated_at),
                  interpolation: {
                    escapeValue: false,
                  },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    },
                  },
                })
              }
              disabled
            />
          </Box>

          <Box sx={{ display: "flex", gap: "16px" }}>
            <TextField
              fullWidth
              label={i18next.t("duimpRequests.deadline")}
              size="small"
              value={
                props.mm_request.deadline &&
                i18next.t("dateTime", {
                  val: Date.parse(props.mm_request.deadline),
                  interpolation: {
                    escapeValue: false,
                  },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    },
                  },
                })
              }
              disabled
            />
            <TextField
              fullWidth
              label={i18next.t("duimpRequests.threat")}
              size="small"
              value={props.mm_request.threat}
              disabled
            />
          </Box>
          <Controller
            name="requester_field"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={requesterFieldOptions}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={i18next.t("duimpRequests.requesterField")}
                    size="small"
                    error={!!errors.requester_field}
                  />
                )}
                onChange={(_, value) => field.onChange(value)}
                disabled={!formIsEditing}
              />
            )}
            rules={{ required: fieldRules.requester_field.required }}
          />
          <Controller
            name="quantity_of_materials"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={quantityOfMaterialOptions}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={i18next.t("duimpRequests.quantityOfMaterials")}
                    size="small"
                    error={!!errors.quantity_of_materials}
                  />
                )}
                onChange={(_, value) => field.onChange(value)}
                disabled={!formIsEditing}
              />
            )}
            rules={{ required: fieldRules.quantity_of_materials.required }}
          />
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "8px" }}>
            <Controller
              name="matnumber"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={{ width: "100%" }}
                  loading={materialsLoading}
                  multiple
                  options={materials}
                  getOptionLabel={(option) => option || ""}
                  onChange={(_, value) => field.onChange(value)}
                  onInputChange={(event, value) => {
                    handleFetchMaterials({
                      material_number: value,
                      free_text: false,
                    });
                  }}
                  noOptionsText={i18next.t("duimpRequests.noOptionsText")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("duimpRequests.material")}
                      size="small"
                      helperText={
                        errors.matnumber
                          ? errors.matnumber.message
                          : i18next.t("duimpRequests.materialHelper")
                      }
                      error={!!errors.matnumber}
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {materialsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        },
                      }}
                    />
                  )}
                  disabled={!formIsEditing}
                />
              )}
              rules={{ required: fieldRules.matnumber.required }}
            />
            <Button
              sx={{ height: "50px", width: "50px" }}
              variant="text"
              color="primary"
              onClick={() => copyToClipboard(getValues("matnumber"))}
              aria-label="Copy to Clipboard"
            >
              <Icon path={mdiContentCopy} size={1} />
            </Button>
          </Box>

          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={i18next.t("duimpRequests.subject")}
                size="small"
                error={!!errors.subject}
                InputProps={{
                  readOnly: !formIsEditing,
                }}
                disabled={!formIsEditing}
              />
            )}
            rules={{ required: fieldRules.subject.required }}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={i18next.t("duimpRequests.description")}
                size="small"
                multiline
                rows={6}
                error={!!errors.description}
                InputProps={{
                  readOnly: !formIsEditing,
                }}
                disabled={!formIsEditing}
              />
            )}
            rules={{ required: fieldRules.description.required }}
          />
          <Controller
            name="quality_deviation"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                sx={{ width: "100%" }}
                multiple
                options={qualityDeviationOptions}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={i18next.t("duimpRequests.qualityDeviation")}
                    size="small"
                    error={!!errors.quality_deviation}
                  />
                )}
                value={Array.isArray(field.value) ? field.value : []}
                onChange={(_, value) => field.onChange(value)}
                disabled={!formIsEditing}
              />
            )}
            rules={{ required: fieldRules.quality_deviation.required }}
          />
          <Controller
            name="quality_deviation_description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={i18next.t("duimpRequests.qualityDeviationDescription")}
                size="small"
                multiline
                rows={3}
                error={!!errors.quality_deviation_description}
                InputProps={{
                  readOnly: !formIsEditing,
                }}
                disabled={!formIsEditing}
              />
            )}
            rules={{
              required: fieldRules.quality_deviation_description.required,
            }}
          />
        </Box>
        {userCanEdit && (
          <Fab
            aria-label="edit"
            type="submit"
            color={formIsEditing ? "success" : "primary"}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
            }}
            disabled={formIsSubmitting || (formIsEditing && !formHasChanged)}
          >
            {formIsSubmitting ? (
              <CircularProgress color="inherit" size={20} />
            ) : formIsEditing ? (
              <Icon path={mdiContentSaveOutline} size={1} />
            ) : (
              <Icon path={mdiPencil} size={1} />
            )}
          </Fab>
        )}
      </form>
      <ConfirmationModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        handleOk={handleSubmitAfterConfirmation}
        title={i18next.t("duimpRequests.modalTitle")}
        message={i18next.t("duimpRequests.modalMessage")}
      />
    </>
  );
};

export default DuimpRequestDetails;
