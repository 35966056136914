import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid2,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { FC, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TermDocument from "../../../@types/services/TermDocument";
import {
  createTermDocument,
  fetchTermDocument,
} from "../../../services/termDocuments";

interface TermDocumentFormProps {
  termDocument: TermDocument;
  setTermDocument: (termDocument: TermDocument) => void;
}

type TermDocumentForm = {
  justification: string;
  attachment_pt_br: File;
  attachment_en: File;
};

const TermDocumentForm: FC<TermDocumentFormProps> = ({
  termDocument,
  setTermDocument,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TermDocumentForm>();

  const onSubmitTermDocument: SubmitHandler<TermDocumentForm> = (data) => {
    if (data.attachment_pt_br || data.attachment_en) {
      const formData = { ...data, term_id: termDocument.term.id };
      setLoading(true);
      createTermDocument(formData, data.attachment_pt_br, data.attachment_en)
        .then((response) => {
          enqueueSnackbar(i18next.t("termDocumentForm.updateDocumentSuccess"), {
            variant: "success",
          });
          fetchTermDocument(response.id).then((document) => {
            setTermDocument(document);
          });
        })
        .catch(() => {
          enqueueSnackbar(i18next.t("termDocumentForm.updateDocumentError"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      enqueueSnackbar(i18next.t("termDocumentForm.requiredDocument"), {
        variant: "error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitTermDocument)}>
      <Grid2 container size={12}>
        <Controller
          name="justification"
          control={control}
          defaultValue={termDocument.justification}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-multiline-flexible"
              label="Justificativa"
              fullWidth
              multiline
              maxRows={4}
            />
          )}
        />
      </Grid2>
      <Grid2 container size={12} sx={{ marginTop: 2 }}>
        <Grid2 size={6}>
          <Controller
            name="attachment_pt_br"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <>
                <InputLabel htmlFor="attachment">
                  {i18next.t("terms.form.filePtBr")}
                </InputLabel>
                <FormControl fullWidth>
                  <Input
                    {...field}
                    type="file"
                    inputProps={{ accept: ".pdf,.doc,.docx" }}
                    id="attachment_pt_br"
                    onChange={(e) =>
                      onChange((e.target as HTMLInputElement).files?.[0])
                    }
                    required
                  />
                </FormControl>
              </>
            )}
          />
        </Grid2>
        <Grid2 size={6}>
          <Controller
            name="attachment_en"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <>
                <InputLabel htmlFor="attachment">
                  {i18next.t("terms.form.fileEn")}
                </InputLabel>
                <FormControl fullWidth>
                  <Input
                    {...field}
                    type="file"
                    inputProps={{ accept: ".pdf,.doc,.docx" }}
                    id="attachment_en"
                    onChange={(e) =>
                      onChange((e.target as HTMLInputElement).files?.[0])
                    }
                    required
                  />
                </FormControl>
              </>
            )}
          />
        </Grid2>
        <Grid2 size={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid2 size={2}>
            <LoadingButton
              type="submit"
              style={{
                marginTop: "8px",
                justifyContent: "center",
              }}
              color="success"
              variant="contained"
              loading={loading}
              loadingPosition="center"
            >
              {i18next.t("termDocumentForm.updateDocument")}
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default TermDocumentForm;
